export const NOTIFICATION = "NOTIFICATION";

export interface NotificationStateSlice {
  readonly id: number;
  readonly message: string | null;
  readonly description: string | null;
}

export interface NotificationModel {
  message: string | null;
  description: string | null;
}

export type NotificationAction = {
  type: typeof NOTIFICATION;
  payload: NotificationModel;
};
export type NotificationAC = (payload: NotificationModel) => NotificationAction;
