import { Documents30DaysResponse } from "types/dashboardApi.types";

export function pieChartLegendLabelFormatter(this: {
  itemDistance: number;
  alignColumns: boolean;
  useHTML: boolean;
  labelFormatters: () => string;
}) {
  return `
    <div style="font-weight: normal; font-size: 14px; line-height: 1.2; font-family: Dustin Sans; margin-bottom: 4px; ">
      <div>${(this as any).name}</div>
      <div style="color: #8D8D8D; font-size:12px; line-height: 1.2">
        ${(this as any).percentage.toFixed(1)}%
      </div>
    </div>`;
}

export const composeChartOptions = (documents30DaysData: Documents30DaysResponse["documents"]) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "pie",
      height: 350,
      spacingBottom: 8,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: [
          { name: "Closed", y: documents30DaysData.closedCount, color: "#4ef09d" },
          { name: "Sent", y: documents30DaysData.totalCount, color: "#44d9fc" },
        ],
      },
    ],
    legend: {
      itemDistance: 20,
      alignColumns: true,
      useHTML: true,
      labelFormatter: pieChartLegendLabelFormatter,
      navigation: {
        arrowSize: 10,
        activeColor: "#000",
        inactiveColor: "#ccc",
        style: {
          fontWeight: "bold",
        },
      },
    },
  };
};
