import { useDispatch, useSelector } from "react-redux";
import { contractActions, contractSelectors } from "../../store/contracts";
import { DropdownProps } from "@skyportal/ui-kit";
import { useCallback } from "react";

interface UseContractDropdown {
  options: DropdownProps["options"];
  handleChange: DropdownProps["onChange"];
  value: string;
}

const useContractDropdown = (): UseContractDropdown => {
  const dispatch = useDispatch();

  return {
    value: useSelector(contractSelectors.getSelectedContractId) ?? "",
    handleChange: useCallback(
      (value) => {
        dispatch(contractActions.selectedContractIdAC(value));
      },
      [dispatch]
    ),
    options: useSelector(contractSelectors.getContractDropdownOptions),
  };
};

export default useContractDropdown;
