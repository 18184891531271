import { RequestStatus } from "types/common.types";
import {
  ClosedDocuments6MonthsResponse,
  Documents30DaysResponse,
  StatusResponse,
  UserCountResponse,
} from "types/dashboardApi.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface DashboardStateSlice {
  readonly status: StatusResponse & WithRequestStatus;
  readonly userCount: UserCountResponse & WithRequestStatus;
  readonly documents30Days: Documents30DaysResponse["documents"] & WithRequestStatus;
  readonly closedDocuments6Months: ClosedDocuments6MonthsResponse & WithRequestStatus;
}

export const STATUS_REQUEST = "STATUS_REQUEST";
export type StatusRequestAction = {
  type: typeof STATUS_REQUEST;
};
export type StatusRequestAC = () => StatusRequestAction;

export const STATUS_REQUEST_SUCCESS = "STATUS_REQUEST_SUCCESS";
export type StatusRequestSuccessAction = {
  type: typeof STATUS_REQUEST_SUCCESS;
  payload: StatusResponse;
};
export type StatusRequestSuccessAC = (payload: StatusResponse) => StatusRequestSuccessAction;

export const STATUS_REQUEST_FAILURE = "STATUS_REQUEST_FAILURE";
export type StatusRequestFailureAction = {
  type: typeof STATUS_REQUEST_FAILURE;
};
export type StatusRequestFailureAC = () => StatusRequestFailureAction;

export const USER_COUNT_REQUEST = "USER_COUNT_REQUEST";
export type UserCountRequestAction = {
  type: typeof USER_COUNT_REQUEST;
};
export type UserCountRequestAC = () => UserCountRequestAction;

export const USER_COUNT_REQUEST_SUCCESS = "USER_COUNT_REQUEST_SUCCESS";
export type UserCountRequestSuccessAction = {
  type: typeof USER_COUNT_REQUEST_SUCCESS;
  payload: UserCountResponse;
};
export type UserCountRequestSuccessAC = (payload: UserCountResponse) => UserCountRequestSuccessAction;

export const USER_COUNT_REQUEST_FAILURE = "USER_COUNT_REQUEST_FAILURE";
export type UserCountRequestFailureAction = {
  type: typeof USER_COUNT_REQUEST_FAILURE;
};
export type UserCountRequestFailureAC = () => UserCountRequestFailureAction;

export const DOCUMENTS_30_DAYS_REQUEST = "DOCUMENTS_30_DAYS_REQUEST";
export type Documents30DaysRequestAction = {
  type: typeof DOCUMENTS_30_DAYS_REQUEST;
};
export type Documents30DaysRequestAC = () => Documents30DaysRequestAction;

export const DOCUMENTS_30_DAYS_REQUEST_SUCCESS = "DOCUMENTS_30_DAYS_REQUEST_SUCCESS";
export type Documents30DaysRequestSuccessAction = {
  type: typeof DOCUMENTS_30_DAYS_REQUEST_SUCCESS;
  payload: Documents30DaysResponse["documents"];
};
export type Documents30DaysRequestSuccessAC = (
  payload: Documents30DaysResponse["documents"]
) => Documents30DaysRequestSuccessAction;

export const DOCUMENTS_30_DAYS_REQUEST_FAILURE = "DOCUMENTS_30_DAYS_REQUEST_FAILURE";
export type Documents30DaysRequestFailureAction = {
  type: typeof DOCUMENTS_30_DAYS_REQUEST_FAILURE;
};
export type Documents30DaysRequestFailureAC = () => Documents30DaysRequestFailureAction;

export const CLOSED_DOCUMENTS_6_MONTHS_REQUEST = "CLOSED_DOCUMENTS_6_MONTHS_REQUEST";
export type ClosedDocuments6MonthsRequestAction = {
  type: typeof CLOSED_DOCUMENTS_6_MONTHS_REQUEST;
};
export type ClosedDocuments6MonthsRequestAC = () => ClosedDocuments6MonthsRequestAction;

export const CLOSED_DOCUMENTS_6_MONTHS_REQUEST_SUCCESS = "CLOSED_DOCUMENTS_6_MONTHS_REQUEST_SUCCESS";
export type ClosedDocuments6MonthsRequestSuccessAction = {
  type: typeof CLOSED_DOCUMENTS_6_MONTHS_REQUEST_SUCCESS;
  payload: ClosedDocuments6MonthsResponse;
};
export type ClosedDocuments6MonthsRequestSuccessAC = (
  payload: ClosedDocuments6MonthsResponse
) => ClosedDocuments6MonthsRequestSuccessAction;

export const CLOSED_DOCUMENTS_6_MONTHS_REQUEST_FAILURE = "CLOSED_DOCUMENTS_6_MONTHS_REQUEST_FAILURE";
export type ClosedDocuments6MonthsRequestFailureAction = {
  type: typeof CLOSED_DOCUMENTS_6_MONTHS_REQUEST_FAILURE;
};
export type ClosedDocuments6MonthsRequestFailureAC = () => ClosedDocuments6MonthsRequestFailureAction;

export type StartPageAction =
  | StatusRequestAction
  | StatusRequestSuccessAction
  | StatusRequestFailureAction
  | UserCountRequestAction
  | UserCountRequestSuccessAction
  | UserCountRequestFailureAction
  | Documents30DaysRequestAction
  | Documents30DaysRequestSuccessAction
  | Documents30DaysRequestFailureAction
  | ClosedDocuments6MonthsRequestAction
  | ClosedDocuments6MonthsRequestSuccessAction
  | ClosedDocuments6MonthsRequestFailureAction;
