// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.esign_styles_fallbackBlock__NNW22 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.esign_styles_fallbackBlockGreying__rXaJ0 {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: #8d8d8d;
  opacity: 0.3;
}

.esign_styles_fallbackReloadBtnWrapper__sizg7 {
  position: relative;
  z-index: 5;
  top: -50%;
  left: 50%;
  width: 32px;
  height: 32px;
  padding: 16px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
}

.esign_styles_fallbackReloadBtnWrapper__sizg7:hover {
  cursor: pointer;
}

.esign_styles_fallbackReloadBtn__mNnc5 {
  width: 32px;
  height: 32px;
  transition: 0.2s;
}

.esign_styles_fallbackReloadBtnWrapper__sizg7:hover .esign_styles_fallbackReloadBtn__mNnc5 {
  opacity: 0.7;
}
`, "",{"version":3,"sources":["webpack://./src/containers/CardFallback/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,SAAS;EACT,WAAW;EACX,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,gCAAgC;AAClC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".fallbackBlock {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\n.fallbackBlockGreying {\n  position: relative;\n  z-index: 5;\n  width: 100%;\n  height: 100%;\n  background-color: #8d8d8d;\n  opacity: 0.3;\n}\n\n.fallbackReloadBtnWrapper {\n  position: relative;\n  z-index: 5;\n  top: -50%;\n  left: 50%;\n  width: 32px;\n  height: 32px;\n  padding: 16px;\n  border-radius: 50%;\n  background-color: #fff;\n  transform: translate(-50%, -50%);\n}\n\n.fallbackReloadBtnWrapper:hover {\n  cursor: pointer;\n}\n\n.fallbackReloadBtn {\n  width: 32px;\n  height: 32px;\n  transition: 0.2s;\n}\n\n.fallbackReloadBtnWrapper:hover .fallbackReloadBtn {\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fallbackBlock": `esign_styles_fallbackBlock__NNW22`,
	"fallbackBlockGreying": `esign_styles_fallbackBlockGreying__rXaJ0`,
	"fallbackReloadBtnWrapper": `esign_styles_fallbackReloadBtnWrapper__sizg7`,
	"fallbackReloadBtn": `esign_styles_fallbackReloadBtn__mNnc5`
};
export default ___CSS_LOADER_EXPORT___;
