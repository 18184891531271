import i18n from "i18next";
import { BreadcrumbsConfigItem } from "./config";

export const makeTitleFromBreadcrumbs = (config: BreadcrumbsConfigItem): string =>
  [
    config.label,
    ...(config.crumbs || [])
      .filter((item) => item.label !== i18n.t("breadcrumbs:start"))
      .map((item) => item.label)
      .reverse(),
    "Skyportal",
  ].join(" - ");
