import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader";
import DashboardCharts from "components/DashboardCharts";
import useFetchDashboardData from "hooks/useFetchDashboardData";

export const Dashboard: React.FC = () => {
  const { t } = useTranslation("esignDashboardPage");
  useFetchDashboardData();

  return (
    <PageLayout>
      <PageHeader title={t("title")} withNavigationLinks />
      <DashboardCharts />
    </PageLayout>
  );
};

export default Dashboard;
