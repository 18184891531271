// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.esign_styles_root__F4Z2C {
  margin-bottom: var(--padding-medium);
}

.esign_styles_titleBlock__ggDIW {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  gap: 16px;
}

.esign_styles_subtitleBlock__rqyeg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.esign_styles_contractDropdown__NCrbG {
  min-width: 240px !important;
  padding-top: 7px !important;
  padding-left: 32px !important;
  margin: 8px 0 !important;
}

@media (max-width: 767px) {
  .esign_styles_titleBlock__ggDIW,
  .esign_styles_subtitleBlock__rqyeg {
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/PageHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;EAC3B,6BAA6B;EAC7B,wBAAwB;AAC1B;;AAEA;EACE;;IAEE,cAAc;EAChB;AACF","sourcesContent":[".root {\n  margin-bottom: var(--padding-medium);\n}\n\n.titleBlock {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n\n.subtitleBlock {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.contractDropdown {\n  min-width: 240px !important;\n  padding-top: 7px !important;\n  padding-left: 32px !important;\n  margin: 8px 0 !important;\n}\n\n@media (max-width: 767px) {\n  .titleBlock,\n  .subtitleBlock {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `esign_styles_root__F4Z2C`,
	"titleBlock": `esign_styles_titleBlock__ggDIW`,
	"subtitleBlock": `esign_styles_subtitleBlock__rqyeg`,
	"contractDropdown": `esign_styles_contractDropdown__NCrbG`
};
export default ___CSS_LOADER_EXPORT___;
