import { RequestStatus } from "types/common.types";
import * as types from "./dashboard.types";

const initState: types.DashboardStateSlice = {
  status: {
    status: "",
    requestStatus: RequestStatus.UNCALLED,
  },
  userCount: {
    userCount: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  documents30Days: {
    totalCount: 0,
    closedCount: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  closedDocuments6Months: {
    closedDocuments: [],
    requestStatus: RequestStatus.UNCALLED,
  },
};

export default function dashboardReducer(
  state: types.DashboardStateSlice = initState,
  action: types.StartPageAction
): types.DashboardStateSlice {
  switch (action.type) {
    case types.STATUS_REQUEST: {
      return {
        ...state,
        status: { ...initState.status, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.STATUS_REQUEST_SUCCESS: {
      return {
        ...state,
        status: {
          status: action.payload.status,
          requestStatus: RequestStatus.SUCCESS,
        },
      };
    }
    case types.STATUS_REQUEST_FAILURE: {
      return {
        ...state,
        status: { ...initState.status, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.USER_COUNT_REQUEST: {
      return {
        ...state,
        userCount: { ...initState.userCount, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.USER_COUNT_REQUEST_SUCCESS: {
      return {
        ...state,
        userCount: { userCount: action.payload.userCount, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.USER_COUNT_REQUEST_FAILURE: {
      return {
        ...state,
        userCount: { ...initState.userCount, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.DOCUMENTS_30_DAYS_REQUEST: {
      return {
        ...state,
        documents30Days: { ...initState.documents30Days, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.DOCUMENTS_30_DAYS_REQUEST_SUCCESS: {
      return {
        ...state,
        documents30Days: {
          totalCount: action.payload.totalCount,
          closedCount: action.payload.closedCount,
          requestStatus: RequestStatus.SUCCESS,
        },
      };
    }
    case types.DOCUMENTS_30_DAYS_REQUEST_FAILURE: {
      return {
        ...state,
        documents30Days: { ...initState.documents30Days, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.CLOSED_DOCUMENTS_6_MONTHS_REQUEST: {
      return {
        ...state,
        closedDocuments6Months: { ...initState.closedDocuments6Months, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.CLOSED_DOCUMENTS_6_MONTHS_REQUEST_SUCCESS: {
      return {
        ...state,
        closedDocuments6Months: {
          closedDocuments: action.payload.closedDocuments,
          requestStatus: RequestStatus.SUCCESS,
        },
      };
    }
    case types.CLOSED_DOCUMENTS_6_MONTHS_REQUEST_FAILURE: {
      return {
        ...state,
        closedDocuments6Months: { ...initState.closedDocuments6Months, requestStatus: RequestStatus.FAILURE },
      };
    }

    default:
      return state;
  }
}
