import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { dashboardSelectors, dashboardThunks } from "store/dashboard";
import { composeChartOptions } from "./utils";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";

import styles from "../../styles.module.css";

const ClosedDocuments6Months = () => {
  const { t } = useTranslation("esignDashboardPage");
  const { requestStatus, ...chartData } = useSelector(dashboardSelectors.getClosedDocuments6Months);

  const chartOptions = useMemo(() => composeChartOptions(chartData.closedDocuments), [chartData]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(
    () => thunkDispatch(dashboardThunks.getClosedDocuments6MonthsInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  return (
    <Card
      className={styles.card}
      title={
        <h3 className={styles.customCardTitle}>
          {t("closedDocuments6MonthsCardTitle")}{" "}
          <span className={styles.customCardSubTitle}>{t("closedDocuments6MonthsCardSubtitle")}</span>
        </h3>
      }
      data-testid="closedDocuments6MonthsCardContent"
    >
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default ClosedDocuments6Months;
