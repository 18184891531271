import * as types from "./notification.types";

const initialGeneralState: types.NotificationStateSlice = {
  id: 0,
  message: null,
  description: null,
};

const generalReducer = (
  state: types.NotificationStateSlice = initialGeneralState,
  action: types.NotificationAction
): types.NotificationStateSlice => {
  const { type, payload } = action;

  switch (type) {
    case types.NOTIFICATION: {
      return {
        ...state,
        id: state.id + 1,
        message: payload.message,
        description: payload.description,
      };
    }
    default:
      return state;
  }
};

export default generalReducer;
