import i18n from "i18next";

export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

export const getBreadcrumbsConfig = () => {
  const StartPage: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:start"),
    path: "/start",
  };

  const eSign: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:esign"),
    path: "/esign/overview",
  };

  const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
    {
      label: eSign.label,
      crumbs: [StartPage],
      match: {
        path: eSign.path,
        exact: true,
      },
    },
  ];

  return breadcrumbsConfig;
};
