import { esignApiClient } from "./clients";
import {
  ClosedDocuments6MonthsResponse,
  Documents30DaysResponse,
  StatusResponse,
  UserCountResponse,
} from "types/dashboardApi.types";

export const getStatus = (contractId: string, signal?: AbortSignal) =>
  esignApiClient.request<StatusResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/esign/status`,
    signal,
  });

export const getUserCount = (contractId: string, signal?: AbortSignal) =>
  esignApiClient.request<UserCountResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/esign/user-count`,
    signal,
  });

export const getDocuments30Days = (contractId: string, signal?: AbortSignal) =>
  esignApiClient.request<Documents30DaysResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/esign/documents-30-days`,
    signal,
  });

export const getClosedDocuments6Months = (contractId: string, signal?: AbortSignal) =>
  esignApiClient.request<ClosedDocuments6MonthsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/esign/closed-documents-6-months`,
    signal,
  });
