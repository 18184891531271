import { AppThunk } from "store";
import * as dashboardApi from "api/dashboard.api";
import * as dashboardActions from "./dashboard.actions";
import i18n from "../../locales";
import { notificationErrorAC } from "../notification/notification.actions";
import { ERROR_CANCELED_CODE, parseApiErrorMessage } from "utils/helpers";

export const getStatusInfo =
  (selectedContractId: string, signal?: AbortSignal): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dashboardActions.statusRequestAC());
      const { data } = await dashboardApi.getStatus(selectedContractId, signal);
      dispatch(dashboardActions.statusRequestSuccessAC(data));
    } catch (apiErrorMessage: any) {
      if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
        console.error(apiErrorMessage, "ESIGN STATUS REQUEST");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:esignStatusRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
        dispatch(dashboardActions.statusRequestFailureAC());
      }
    }
  };

export const getUserCountInfo =
  (selectedContractId: string, signal?: AbortSignal): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dashboardActions.userCountRequestAC());
      const { data } = await dashboardApi.getUserCount(selectedContractId, signal);
      dispatch(dashboardActions.userCountRequestSuccessAC(data));
    } catch (apiErrorMessage: any) {
      if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
        console.error(apiErrorMessage, "ESIGN USER COUNT REQUEST");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:esignUserCountRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
        dispatch(dashboardActions.userCountRequestFailureAC());
      }
    }
  };

export const getDocuments30DaysInfo =
  (selectedContractId: string, signal?: AbortSignal): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dashboardActions.documents30DaysRequestAC());
      const { data } = await dashboardApi.getDocuments30Days(selectedContractId, signal);
      dispatch(dashboardActions.documents30DaysRequestSuccessAC(data.documents));
    } catch (apiErrorMessage: any) {
      if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
        console.error(apiErrorMessage, "ESIGN DOCUMENTS 30 DAYS REQUEST");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:esignDocuments30DaysRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
        dispatch(dashboardActions.documents30DaysRequestFailureAC());
      }
    }
  };

export const getClosedDocuments6MonthsInfo =
  (selectedContractId: string, signal?: AbortSignal): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dashboardActions.closedDocuments6MonthsRequestAC());
      const { data } = await dashboardApi.getClosedDocuments6Months(selectedContractId, signal);
      dispatch(dashboardActions.closedDocuments6MonthsRequestSuccessAC(data));
    } catch (apiErrorMessage: any) {
      if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
        console.error(apiErrorMessage, "ESIGN CLOSED DOCUMENTS 6 MONTHS REQUEST");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:esignClosedDocuments6MonthsRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
        dispatch(dashboardActions.closedDocuments6MonthsRequestFailureAC());
      }
    }
  };
