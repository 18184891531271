const config = {
  baseUrl: String((window as any).BASE_URL),
  widgetHost: String((window as any).WIDGET_HOST),
  authWebUrl: String((window as any).AUTH_WEB_URL),
  authApiUrl: String((window as any).AUTH_API_URL),
  eSignApiUrl: String((window as any).ESIGN_API_URL),
  eSignScriveLink: String((window as any).ESIGN_SCRIVE_LINK),
};

export default config;
