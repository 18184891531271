import * as types from "./dashboard.types";

export const statusRequestAC: types.StatusRequestAC = () => ({
  type: types.STATUS_REQUEST,
});

export const statusRequestSuccessAC: types.StatusRequestSuccessAC = (payload) => ({
  type: types.STATUS_REQUEST_SUCCESS,
  payload,
});

export const statusRequestFailureAC: types.StatusRequestFailureAC = () => ({
  type: types.STATUS_REQUEST_FAILURE,
});

export const userCountRequestAC: types.UserCountRequestAC = () => ({
  type: types.USER_COUNT_REQUEST,
});

export const userCountRequestSuccessAC: types.UserCountRequestSuccessAC = (payload) => ({
  type: types.USER_COUNT_REQUEST_SUCCESS,
  payload,
});

export const userCountRequestFailureAC: types.UserCountRequestFailureAC = () => ({
  type: types.USER_COUNT_REQUEST_FAILURE,
});

export const documents30DaysRequestAC: types.Documents30DaysRequestAC = () => ({
  type: types.DOCUMENTS_30_DAYS_REQUEST,
});

export const documents30DaysRequestSuccessAC: types.Documents30DaysRequestSuccessAC = (payload) => ({
  type: types.DOCUMENTS_30_DAYS_REQUEST_SUCCESS,
  payload,
});

export const documents30DaysRequestFailureAC: types.Documents30DaysRequestFailureAC = () => ({
  type: types.DOCUMENTS_30_DAYS_REQUEST_FAILURE,
});

export const closedDocuments6MonthsRequestAC: types.ClosedDocuments6MonthsRequestAC = () => ({
  type: types.CLOSED_DOCUMENTS_6_MONTHS_REQUEST,
});

export const closedDocuments6MonthsRequestSuccessAC: types.ClosedDocuments6MonthsRequestSuccessAC = (payload) => ({
  type: types.CLOSED_DOCUMENTS_6_MONTHS_REQUEST_SUCCESS,
  payload,
});

export const closedDocuments6MonthsRequestFailureAC: types.ClosedDocuments6MonthsRequestFailureAC = () => ({
  type: types.CLOSED_DOCUMENTS_6_MONTHS_REQUEST_FAILURE,
});
