import { ClosedDocumentsModel } from "types/dashboardApi.types";

export function areaSplineLegendLabelFormatter(this: {
  itemDistance: number;
  alignColumns: boolean;
  useHTML: boolean;
  labelFormatters: () => string;
}) {
  return `
    <div style="font-weight: normal; font-size: 14px; line-height: 1.2; margin-bottom: 6px;">
      <div>${(this as any).name}</div>
    </div>`;
}

export const composeChartOptions = (closedDocumentsData: ClosedDocumentsModel[]) => {
  closedDocumentsData.sort((a, b) => (a.month > b.month ? 1 : b.month > a.month ? -1 : 0));

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      spacingTop: 20,
      spacingBottom: 0,
      plotShadow: false,
      type: "line",
      height: 350,
      style: {
        fontFamily: "Dustin Sans",
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      split: true,
    },
    accessibility: {
      point: {
        valueSuffix: "pcs",
      },
    },
    yAxis: [
      {
        title: { text: "Documents" },
        min: 0,
        gridLineWidth: 0,
      },
    ],
    xAxis: {
      title: null,
      type: "datetime",
      gridLineWidth: 1,
    },
    plotOptions: {
      line: {
        animation: {
          duration: 1500,
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Documents",
        data: closedDocumentsData.map((item) => {
          const timestamp = Number(new Date(item.month));
          return { x: timestamp, y: item.count };
        }),
        color: "#c48df7",
        lineWidth: 3,
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b><br>`,
        },
      },
    ],
    legend: {
      itemDistance: 20,
      alignColumns: true,
      useHTML: true,
      labelFormatter: areaSplineLegendLabelFormatter,
    },
  };
};
