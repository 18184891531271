import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { dashboardSelectors, dashboardThunks } from "store/dashboard";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";

import styles from "../../styles.module.css";

const UserCountCard = () => {
  const { t } = useTranslation("esignDashboardPage");
  const { requestStatus, userCount } = useSelector(dashboardSelectors.getUserCount);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(
    () => thunkDispatch(dashboardThunks.getUserCountInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  return (
    <Card className={styles.card} title={t("userCountCardTitle")} data-testid="userCountCardContent">
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && <div className={styles.userCountBlock}>{userCount}</div>}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default UserCountCard;
