import axios from "axios";
import config from "config";
import createAuthClient from "@skyportal/auth-web-client";

export const authClient = createAuthClient({
  authApiURL: config.authApiUrl,
  authWebURL: config.authWebUrl,
});

export const esignApiClient = axios.create({
  baseURL: config.eSignApiUrl,
  withCredentials: true,
});
